import React from 'react'
import { UserContext} from '../Providers'


class HeaderProfile extends React.Component{

    static contextType = UserContext;
  
      constructor(props) {
          super(props)
  
           this.HoverMouse = this.HoverMouse.bind(this);
           this.LeaveMouse = this.LeaveMouse.bind(this);
           this.LogOut = this.LogOut.bind(this);
           this.GoToProfileMode = this.GoToProfileMode.bind(this);
      }

  
        state = {
            show: false,
            showMenu : false,
            goTo: '',
        }
        GoToProfileMode(goto){
          this.context.setProfileMode(true, goto );
        }

      
        LogOut(){
            this.context.setUser(-1, '','', '', '', '');
            this.context.userObject = { RecID: -1, name: '', token: '', email: '', imageUrl: '', sCPSubject: '', sCPComments: '', sToFaxNumber: '', sCPToName: '', accountStatus: '' };
            this.context.showHeaderMenu = false;

            this.context.setFax('','','','');
            this.context.faxObject = { sCPSubject: '', sCPComments: '', sToFaxNumber: '', sCPToName: ''};
            this.context.clearFiles();

            this.context.setProfileMode(false);

           this.setState({...this.state,
            showMenu: false});
        }
      
      HoverMouse(){
        this.setState({...this.state,
            showMenu: true});
    }

      
      LeaveMouse(){
          setTimeout(() => {this.setState({...this.state,
            showMenu: false})}, 1000)
      }
  
      componentDidMount() {
        if(this.context.userObject.RecID !== -1){
            this.setState({...this.state,show: true});         }
      }
    
      render() {
        if (this.props.showMenu === false) {
           return <div></div>
         }

        return (


          <div className='dropdown'>
              <div className="dropbtn"><div className="textContainer">Hi, {this.context.userObject.name.substr(0,this.context.userObject.name.indexOf(' '))} !   <img src='./images/arrowdown.svg' alt='' width='20px'/></div> <div className="imageContainer"> <img src={this.context.userObject.imageUrl} alt='' className='rounded-circle' width='50px' /></div></div>
              <div className="dropdown-content">
              <button onClick={() =>this.GoToProfileMode('/inbox')} className={this.context.ProfileMode !== true ? "" : "hideme"}>Inbox
              </button>
              <button  onClick={() =>this.GoToProfileMode('/userprofile')} className={this.context.ProfileMode !== true ? "" : "hideme"}>Profile
              </button>
              <button onClick={this.LogOut} className="">Logout</button>
                  </div>
          </div>
        );
      }
    
    };
  
    export default HeaderProfile;