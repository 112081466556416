import React from 'react'

class WorkingDiv extends React.Component{

  
	  render() {
        
		  return (
		  	 <div>


                <div className={(this.props.working === false)? 'hideme' : 'modal-background'}></div>
            <div className={(this.props.working === false)? 'hideme' : 'modal-window'}>
                <div className="modal-window-content">
                    <img src="/Images/ring.gif" alt="Loading" />
                    <span className="loader-label">Processing...</span>
                </div>
            </div>

            </div>
		     
		  )
}
};

export default WorkingDiv;