import React from 'react'
import { UserContext} from '../Providers'
import WorkingDiv  from '../WorkingDiv'

import APIClient from '../API'
import RouteDecider  from '../RouteDecider'

class Account extends React.Component{

    static contextType = UserContext;
  
    state = {
		goTo: '',
        working: false,
        Inbox: "0",
        Outbox:"0",
        AccoutnStatus: '-',
        warning: ''
	  }
      
      componentWillMount(){

        if(this.context.ProfileMode === false){
            this.setState({...this.state,
              goTo: '/coverletter'});
          }

        this.setState({...this.state,
            working: true});

            APIClient.getAccountData(this.context.userObject.RecID)
			.then((data) =>
			{
                this.setState({...this.state,
                    Inbox: data.inBox,
                    Outbox: data.outBox,
                    AccoutnStatus: data.status,
                    warning: (data.status === 'Signup')? 'This section only works for subscribed users.': '', 
                    working: false});
			});
      }


      componentDidUpdate(prevProps) {
        if (this.context.ProfileMode === false){
          this.setState({...this.state,
            goTo: 'coverletter'});
        }
    }

      render() {

    /*    console.log(this.state.Inbox);
        if (this.context.userObject.RecID === -1) {
			return <Redirect to='/' />
		  }

        
        if (this.context.ProfileMode === false) {
			return <Redirect to='/coverletter' />
		  }		*/		
       
        return (


          <div>

          <RouteDecider  GoTo={this.state.goTo} CheckProfieMode={false}></RouteDecider>
             <WorkingDiv working={this.state.working}></WorkingDiv>
				

				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h3>Account</h3>
					</div>
				</div><br/>
                <div className='row'>
					<div className='col-lg-4 col-md-4 col-sm-6 col-xs-6'>
						Account Status:
					</div>
                    <div className='col-lg-4 col-md-6 col-sm-6 col-xs-6 text-right'>
						<b>{this.state.AccoutnStatus}</b>
					</div>
				</div><br/>
                <div className='row'>
					<div className='col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                    Inbound Fax Pages This Month:
					</div>
                    <div className='col-lg-4 col-md-6 col-sm-6 col-xs-6 text-right'>
						{this.state.Inbox}
					</div>
				</div>
                <br/>
                <div className='row'>
					<div className='col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                    Outbound Fax Pages This Month:
					</div>
                    <div className='col-lg-4 col-md-6 col-sm-6 col-xs-6 text-right'>
						{this.state.Outbox}
					</div>
				</div>

                <div className='row warning1'>
					<div className='col-lg-8 col-md-8 col-sm-12 col-xs-12'>
                        {this.state.warning}
					</div>
				</div>

          </div>
        );
      }
    
    };
  
    export default Account;