import React from 'react'

import { UserContext } from '../Providers'
import WorkingDiv from '../WorkingDiv'
import RouteDecider from '../RouteDecider'
import APIClient from '../API'
import {CardNumberElement, injectStripe, CardExpiryElement, CardCVCElement} from 'react-stripe-elements';

class PaymentInfo extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props)

    this.Submit = this.Submit.bind(this);

    this.createOptions = this.createOptions.bind(this);
  }

  state = {
    goTo: '',
    working: false,
    ClientInfo: []
  }

  Submit() {

    if(this.state.working === true){
      return;
    }

    this.setState({...this.state, working: true });

    this.props.stripe.createSource({
      type: 'card',
      owner: {
        name: this.context.userObject.name,
        email: this.context.userObject.email
      },
    }).then((payload) =>
    {
   //   console.log(payload);
      if(payload.error !== undefined ){
        alert(payload.error.message);
        this.setState({...this.state, working: false });
        return;
      }
      else{
        if(payload.source !== undefined){
          APIClient.updateCC(this.context.userObject.RecID, payload.source.id).then((data)=> {
            //console.log(data);
              if(data === 'Wow!'){
                  alert('Your Credit Card Info Updated Successfully.');
                  this.setState({...this.state,goTo: '/coverletter'});
              }
              else{
                  alert('Could not update your info.');
                  this.setState({...this.state, working: false });
              }


          })
        }
        }
       /* */

      


     
    });
  }
  

  componentDidUpdate(prevProps) {


  }

  componentWillMount() {



  }

  createOptions() {
    return {
      style: {
        base: {
          color: '#424770',
          letterSpacing: '0.085em',
          fontFamily: 'ProximaNova-Regular, monospace',
          '::placeholder': {
            color: '#aab7c4',
          }
          

        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  }

  render() {



    return (


      <div className="cardSection">
        <RouteDecider GoTo={this.state.goTo} CheckProfieMode={false} InsideProfile={true}></RouteDecider>
        <WorkingDiv working={this.state.working}></WorkingDiv>


        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <h3>Payment Info</h3>
          </div>
        </div>


    

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
          <label>Credit Card Number</label>
            <CardNumberElement {...this.createOptions()} className="form-control" />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-5 col-md-11 col-sm-12 col-xs-12'>
          <label>Expiration Date</label>
            <CardExpiryElement {...this.createOptions()}  className="form-control" />
          </div>
       
          <div className='col-lg-5 col-md-11 col-sm-12 col-xs-12'>
          <label>CVC</label>
            <CardCVCElement {...this.createOptions()} className="form-control" />
          </div>
        </div>
        <br />

        <div className="form-group">
        <button className='button-1' onClick={this.Submit}>Submit Payment</button>
       </div>

        <br /><br /><br />
      </div>
    );
  }

};

export default injectStripe(PaymentInfo);