import React from 'react'
import { UserContext } from '../Providers'
import { Redirect } from "react-router-dom";

class RouteDecider extends React.Component {

  static contextType = UserContext;

  //static contextFaxType = FaxContext;


  render() {
    
    if (this.props.GoTo !== '' && this.props.GoTo !== undefined) {
      return <Redirect to={this.props.GoTo} />
    }

    if (this.context.userObject.RecID === -1) {
      return <Redirect to='/' />
    }

    if (this.props.CheckFiles === true) {
      if (this.context.files.length === 0) {
        return <Redirect to='/attachfile' />
      }
    }

    if (this.props.CheckFaxNumber === true) {
      if (this.context.faxObject.sToFaxNumber === '') {
        return <Redirect to='/coverletter' />
      }
    }

    if (this.props.CheckProfieMode === true) {
      if (this.context.ProfileMode === true) {
        return <Redirect to={this.context.nextGoto} />
      }
      else
        return <Redirect to='/coverletter' />
    }

    if (this.props.InsideProfile === true) {
      if (this.context.ProfileMode !== true) {
        return <Redirect to='/coverletter' />
      }
    }

    if( this.context.nextGoto !== '' && this.props.HaveProfileLinkOutsideProfile === true){
      return <Redirect to={this.context.nextGoto} />
    }


    return (<div></div>)
  }
};

export default RouteDecider;