import React from 'react'

import { UserContext } from '../../Providers'
import WorkingDiv from '../../WorkingDiv'
import RouteDecider from '../../RouteDecider'
import APIClient from '../../API'
import Utility from '../../API/util'

class EmailPreference extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props)

        this.SaveEmail = this.SaveEmail.bind(this);
        this.PrimaryEmail = React.createRef();
        this.Email1 = React.createRef();
        this.Email2 = React.createRef();
        this.TimeZone = React.createRef();
    }

    state = {
        goTo: '',
        working: false,
        ClientInfo: []
    }

    SaveEmail() {

        if (this.PrimaryEmail.current.value === '') {
            alert('Please enter a valid Primary Email.');
            return;
        }

        if ( Utility.validateEmail(this.PrimaryEmail.current.value) !== true) {
            alert('Please enter a valid Primary Email.');
            return;
        }


        if (this.Email1.current.value !== '' &&  Utility.validateEmail(this.Email1.current.value) !== true) {
            alert('Email 1 is not valid.');
            return;
        }

        if (this.Email2.current.value !== '' &&  Utility.validateEmail(this.Email2.current.value) !== true) {
            alert('Email 1 is not valid.');
            return;
        }

        this.setState({
            ...this.state,
            working: true
        });

        APIClient.saveEmailProfile(this.context.userObject.RecID, this.PrimaryEmail.current.value, this.Email1.current.value, this.Email2.current.value, this.TimeZone.current.value).then((data) => {
            if (data === 'Wow') {
                alert('Email preference updated.');
            }
            else {
                alert('Could not update email preference.');
            }

            //console.log(data);

            this.setState({
                ...this.state,
                working: false,
            });
        }
        )
    }

    componentDidUpdate(prevProps) {

        if (this.context.ProfileMode === false) {
            this.setState({
                ...this.state,
                goTo: 'coverletter'
            });
        }

    }

    componentWillMount() {

        this.setState({
            ...this.state,
            working: true
        });

        APIClient.getEmailProfile(this.context.userObject.RecID).then((data) => {
             
            if (data !== null && this.TimeZone.current != null)
               this.TimeZone.current.value = data.timeZone;

            this.setState({
                ...this.state,
                working: false,
                ClientInfo: data
            });
        }

        )
    }


    render() {



        return (


            <div>
                <RouteDecider GoTo={this.state.goTo} CheckProfieMode={false} InsideProfile={true}></RouteDecider>
                <WorkingDiv working={this.state.working}></WorkingDiv>


                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <h3>Email Preference</h3>
                    </div>
                </div>
                <br />



                <div className='row  margin-top-15'>
                    <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
                        <label>Primary Email:</label>
                        <input type="text" ref={this.PrimaryEmail} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.primaryEmail : ''} />
                    </div>
                </div>

                <div className='row  margin-top-15'>
                    <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
                        <label>Send From Email #1</label>
                        <input type="text" ref={this.Email1} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.user1Email : ''} />
                    </div>
                </div>


                <div className='row  margin-top-15'>
                    <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
                        <label>Send From Email #2</label>
                        <input type="text" ref={this.Email2} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.user2Email : ''} />
                    </div>
                </div>



                <div className='row  margin-top-15'>
                    <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
                        <label>Time Zone:</label>
                        <select defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.timeZone : ''} ref={this.TimeZone}>
                            <option value="Canada/Atlantic">Canada/Atlantic</option>
                            <option value="Canada/Central" >Canada/Central</option>
                            <option value="Canada/East-Saskatchewan" >Canada/East-Saskatchewan</option>
                            <option value="Canada/Eastern" >Canada/Eastern</option>
                            <option value="Canada/Mountain" >Canada/Mountain</option>
                            <option value="Canada/Newfoundland" >Canada/Newfoundland</option>
                            <option value="Canada/Pacific" >Canada/Pacific</option>
                            <option value="Canada/Saskatchewan" >Canada/Saskatchewan</option>
                            <option vlue="Canada/Yukon" >Canada/Yukon</option>
                            <option value="US/Alaska" >US/Alaska</option>
                            <option value="US/Aleutian" >US/Aleutian</option>
                            <option value="US/Arizona" >US/Arizona</option>
                            <option value="US/Central" >US/Central</option>
                            <option value="US/East-Indiana" >US/East-Indiana</option>
                            <option value="US/Eastern" >US/Eastern</option>
                            <option value="US/Hawaii" >US/Hawaii</option>
                            <option value="US/Indiana-Starke" >US/Indiana-Starke</option>
                            <option value="US/Michigan" >US/Michigan</option>
                            <option value="US/Mountain" >US/Mountain</option>
                            <option value="US/Pacific" >US/Pacific</option>
                            <option value="US/Pacific-New" >US/Pacific-New</option>
                            <option value="US/Samoa" >US/Samoa</option>
                        </select>
                    </div>
                </div>


                <br />
                <div className='row  margin-top-15'>
                    <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>

                        <button className='button-1' onClick={this.SaveEmail}>Update Email Preference</button>
                    </div>
                </div>

                <br /><br /><br />
            </div>
        );
    }

};

export default EmailPreference;