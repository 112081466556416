import React from 'react'
import { UserContext } from '../Providers'
import APIClient from '../API'
import WorkingDiv from '../WorkingDiv'
import RouteDecider from '../RouteDecider'

class ChooseNumber extends React.Component {

	constructor(props) {
		super(props)

		this.TollFree = React.createRef();
		this.AreaCode = React.createRef();
		this.Number = React.createRef();

		this.handleChange = this.handleChange.bind(this);
		this.AreaCodeChanged = this.AreaCodeChanged.bind(this);
		this.UpdateNumbers = this.UpdateNumbers.bind(this);
		this.Skip = this.Skip.bind(this);
		this.Next = this.Next.bind(this);
	}

	static contextType = UserContext;

	state = {
		goTo: '',
		tollFree: true,
		areacodes: null,
		numbers: null,
		working: true,
		showSkipStepButton: false
	}

	componentWillMount() {


		if (this.context.userObject.RecID === -1) {
			this.setState({	...this.state,	goTo: '/'	});
		}
		else if(this.context.faxObject.sToFaxNumber === ''){
			this.setState({...this.state,goTo: '/coverletter'});
		}

		else if (this.context.files.length === 0) {
			this.setState({	...this.state,	goTo: '/attachfile'	});
		}
		else {

			this.setState({...this.state,working: true});

			APIClient.getUserStatus(this.context.userObject.RecID).then((data) =>{
				// if user is active, we just go to sendfax page
				if(data === 'Active'){this.setState({...this.state, goTo: '/sendfax'});
				}
				//if user is unpaid or canceled we will ask them to choose number
				/*else if (data === 'Canceled' || data === 'Unpaid'){}*/
				// if user is trial we check trial conditions
				else{
					var filenames = "";
					this.context.files.forEach(item => filenames += "," + item.filename);
					
					APIClient.checkPageCounts(this.context.userObject.RecID, filenames)
						.then((data1) => {
							if (parseInt(data1) > 2) {
								alert("Your attachment is more than 2 pages. Everything’s fine😊, we only ask you start a $1 trial. Send and receive up to 200 pages per month, from this app or directly from Gmail.");
		
								this.setState({	...this.state,showSkipStepButton: false});
								APIClient.getTollFreeAreaCodes().then((data) =>
									this.setState({ ...this.state, areacodes: data, ...this.UpdateNumbers(data[0].id, data[0].city) }))
							}
							else if (parseInt(data1) === 0) {
								this.setState({...this.state,goTo: '/attachfile',working: false});
							}
							else if (parseInt(data1) === 1 || parseInt(data1) === 2) {
								APIClient.getTollFreeAreaCodes().then((data) =>
									this.setState({ ...this.state, areacodes: data,  showSkipStepButton: true, ...this.UpdateNumbers(data[0].id, data[0].city) }))
							}
							else if (parseInt(data1) === -1) {
								alert("Everything’s fine😊, we only ask you start a $1 trial. Send and receive up to 200 pages per month, from this app or directly from Gmail.");
								APIClient.getTollFreeAreaCodes().then((data) =>
									this.setState({ ...this.state, areacodes: data, ...this.UpdateNumbers(data[0].id, data[0].city) }))
							}
		
		
		
						});
				}
				
			});
			}

	}

	updateAreaCode(value) {
		if (value === '1') {
			APIClient.getTollFreeAreaCodes().then((data) =>
				this.setState({ ...this.state, areacodes: data,working: true, ...this.UpdateNumbers(data[0].id, data[0].city) }),

			)
		}
		else {
			APIClient.getPaidAreaCodes().then((data) =>
				this.setState({ ...this.state, areacodes: data,working: true, ...this.UpdateNumbers(data[0].id, data[0].city) }),

			)
		}


	}

	UpdateNumbers(id, text) {
		APIClient.getGetPaidNumbers(id, text).then((data) =>
			this.setState({
				...this.state,
				numbers: data,
				working: false
			})
		)
	}

	AreaCodeChanged(event) {

		this.setState({
			...this.state,
			working: true
		});

		var index = event.nativeEvent.target.selectedIndex;

		APIClient.getGetPaidNumbers(event.target.value, event.nativeEvent.target[index].text).then((data) =>
			this.setState({
				...this.state,
				numbers: data,
				working: false
			})
		)
	}

	handleChange(event) {
		this.updateAreaCode(event.target.value);

		this.setState({
			...this.state,
			tollFree: (event.target.value === '2') ? false : true
		});
	}

	Skip() {
		this.setState({
			...this.state,
			goTo: '/sendfax'
		});
	}

	Next() {

		//console.log(this.Number.current.value);
		this.context.setsSRFaxNumber(this.Number.current.value);

		this.setState({
			...this.state,
			goTo: '/chooseplan'
		});
	}

	render() {

		return (

			<div>
				<RouteDecider CheckFiles={true} GoTo={this.state.goTo} CheckProfieMode={this.context.ProfileMode}></RouteDecider>
				<WorkingDiv working={this.state.working}></WorkingDiv>


				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h3>Fax Numbers</h3>
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 subheader'>
						<h4>Choose Your Fax Number - $1 Trial for 7-Days</h4><br />
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-4 col-md-6 col-sm-6 col-xs-6 '>
						<div className="radioDiv">
							<input ref={this.TollFree} checked={this.state.tollFree === true} value="1" onChange={this.handleChange} type="radio" name="toolfree"></input>
							<span>Toll Free Number</span>
						</div>

					</div>


					<div className='col-lg-4 col-md-6 col-sm-6 col-xs-6 '>
						<div className="radioDiv">
							<input type="radio" value="2" checked={this.state.tollFree === false} onChange={this.handleChange} name="toolfree"></input>
							<span>Local Number</span>
						</div>

					</div>

				</div>
				<br />

				<div className='row'>
					<div className='col-lg-4 col-md-6 col-sm-6 col-xs-6 '>
						<label>Area Code:</label>
						<select ref={this.AreaCode} onChange={this.AreaCodeChanged}>
							{(this.state.areacodes === null) ? '' : this.state.areacodes.map((r, key) => <option value={r.id} key={key}>{r.city}</option>)}
						</select>
					</div>


					<div className='col-lg-4 col-md-6 col-sm-6 col-xs-6 '>
						<label>Fax Number:</label>
						<select ref={this.Number} name="faxnumber">
							{(this.state.numbers === null) ? '' : this.state.numbers.map((r, key) => <option value={r.id} key={key}>{r.number}</option>)}
						</select>
					</div>

				</div>

				<br /><br />
				<div className='row'>
					<div className={(this.state.showSkipStepButton === true) ? 'col-lg-4 col-md-12 col-sm-12 col-xs-12' : 'col-lg-4 col-md-12 col-sm-12 col-xs-12 hideme'}>
						<button name="skipthis" onClick={this.Skip} className="nextBtn">Skip This Step</button>
					</div>
					<div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
						<button name="nextstep3" onClick={this.Next} className="nextBtn">Next Step</button>
					</div>
				</div>
				<br />

				<br />
			</div>


		)
	}
};

export default ChooseNumber;