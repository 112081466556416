import React from 'react'
import { Link, withRouter } from "react-router-dom";
import { UserContext} from '../Providers';

class MyMenu extends React.Component{

  static contextType = UserContext;

    constructor(props) {
        super(props);

        this.BackToSendFax = this.BackToSendFax.bind(this);
    }

    BackToSendFax(){
        if(this.context.ProfileMode === true)
            this.context.setProfileMode(false, '/coverletter');
    }

  RenderMainMenu(){
    var signUpclass = "";
    var signUpclassText = "";

    var coverclass = "";
    var coverclassText = "";

    var attachclass = "";
    var attachclassText = "";

    var numberclass = "";
    var numberclassText = "";

    var sendclass = "";
    var sendclassText = "";


    if(this.props.location.pathname === "/")
    {
      signUpclass = "circle currentMenu";
      signUpclassText = "currentMenuText";

      coverclass = "circle2";
      coverclassText = "";

      attachclass = "circle2";
      attachclassText = "";

      numberclass = "circle2";
      numberclassText = "nosending";

      sendclass = "circle2";
      sendclassText = "nosending";


    }

    
    else if(this.props.location.pathname === "/coverletter")
    {
      signUpclass = "circle ";
      signUpclassText = "";

      coverclass = "circle currentMenu";
      coverclassText = "currentMenuText";

      attachclass = "circle2";
      attachclassText = "";

      numberclass = "circle2";
      numberclassText = "nosending";

      sendclass = "circle2";
      sendclassText = "nosending";
    }

    else if(this.props.location.pathname === "/attachfile")
    {
      signUpclass = "circle ";
      signUpclassText = "";

      coverclass = "circle";
      coverclassText = "";

      attachclass = "circle currentMenu";
      attachclassText = "currentMenuText";

      numberclass = "circle2";
      numberclassText = "nosending";

      sendclass = "circle2";
      sendclassText = "nosending";
    }

    else if(this.props.location.pathname === "/choosenumber" || this.props.location.pathname === "/chooseplan")
    {
      signUpclass = "circle ";
      signUpclassText = "";

      coverclass = "circle";
      coverclassText = "";

      attachclass = "circle";
      attachclassText = "";

      numberclass = "circle currentMenu";
      numberclassText = "currentMenuText";

      sendclass = "circle2";
      sendclassText = "nosending";
    }

    else if(this.props.location.pathname === "/sendfax" )
    {
      signUpclass = "circle ";
      signUpclassText = "";

      coverclass = "circle";
      coverclassText = "";

      attachclass = "circle";
      attachclassText = "";

      numberclass = "circle";
      numberclassText = "nosending";

      sendclass = "circle currentMenu";
      sendclassText = "currentMenuText";
    }
    else   
    {
      signUpclass = "circle ";
      signUpclassText = "";

      coverclass = "circle";
      coverclassText = "";

      attachclass = "circle";
      attachclassText = "";

      numberclass = "circle";
      numberclassText = "nosending";

      sendclass = "circle";
      sendclassText = "nosending";
    }


    return (
      
          <div className='main-nav navbar-dark'>
          <div className='NavItem'>
          
          <span className={signUpclass}>1</span><Link className={ signUpclassText } to="/">SignUp</Link>
          </div>
          <div className='NavItem'>
            <span className={coverclass}>2</span><Link className={ coverclassText } to="/coverletter">Cover Letter</Link>
          </div>
          <div className='NavItem'>
          <span className={attachclass}>3</span><Link className={ attachclassText} to="/attachfile">Attach Files</Link>
          </div>

          <div className='NavItem'>
          <span className={numberclass}>4</span><Link className={numberclassText} to="/choosenumber">Fax Number</Link>
          </div>

          <div className='NavItem'>
          <span className={sendclass}>5</span><Link className={sendclassText} to="/sendfax">Send Fax</Link>
          </div>
        </div>
          
       
    )
  }

RendProfileMenu(){
  return (
    <div className='main-nav navbar-dark navbar-2'>
          <div className='NavItem'>
          <Link className={(this.props.location.pathname === "/inbox")? "currentMenu" : ""} to="/inbox">Inbox</Link>
          </div>
          <div className='NavItem'>
            <Link className={(this.props.location.pathname === "/outbox")? "currentMenu" : ""} to="/outbox">Outbox</Link>
          </div>
          <div className='NavItem'>
          <Link className={(this.props.location.pathname === "/account")? "currentMenu" : ""} to="/account">Account</Link>
          </div>

          <div className='NavItem'>
          <Link className={(this.props.location.pathname.includes("/userprofile"))? "currentMenu" : ""} to="/userprofile">User Profile</Link>
          </div>

        <div className='NavItem'>
            <button className="" onClick={this.BackToSendFax}>Send Fax</button>
        </div>
        </div>
  )
}

	  render() {

           if(this.context.ProfileMode !== true)
           return this.RenderMainMenu();
           else
           return this.RendProfileMenu();
        }
};

export default withRouter(MyMenu);