import React from 'react'
import { UserContext} from '../Providers'

class FilesGrid extends React.Component{
    
    static contextType = UserContext;
    
	
	RemoveMe(index){
		
		this.context.removeFile(index);

    }
    
	  render() {
	
		  return (

            
				<div className='row'>
					<div className='col-lg-9 col-md-12 col-sm-12 col-xs-12'>
					{ (this.context.files === [])? '' : this.context.files.map((r,key) => <div key={key} className='filerow'>{r.filename}
						<div className={(this.props.showRemove === 'true')?'removeBtn' : 'hideme'}  onClick={() => this.RemoveMe(key)}>Remove <img src='images/remove.svg' alt='' width='20'></img></div>	
					</div>)}
					</div>
				</div>


            
		     
		  )
}
};

export default FilesGrid;