import React from 'react'
import LoginButton from '../LoginButton'
import { UserContext} from '../Providers'
import {Redirect } from "react-router-dom";

class SignUp extends React.Component{

    static contextType = UserContext;

	  render() {
        
        if (this.context.userObject.RecID !== -1) {
            return <Redirect to='/coverletter' />
            }
    
            
		  return (
		  	 
        <div>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <h3>Sign Up</h3>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 subheader'>
                <h4>Let’s create your FREE account and send a test fax right now!</h4><br/><br/>
                </div>
                
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <LoginButton></LoginButton>
                </div>
                <br/><br/>
               </div>
          </div>
            
		     
		  )
}
};

export default SignUp;