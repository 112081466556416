import React from 'react'

import {UserContext} from "../Providers";
import RouteDecider from "../RouteDecider";


class FaxSent extends React.Component{

	static contextType = UserContext;

	constructor(props) {
		super(props)

		this.setRedirect = this.setRedirect.bind(this);
	}

	state = {
		goTo: '',
		working: false
	  }

	setRedirect(path) {

		if(path === '/outbox' || path === '/inbox' || path === '/userprofile'){
			this.context.setProfileMode(true, path);
		}
		else{
			this.setState({goTo: path});
		}


	}

	  render() {

		  return (
		  	 
		  	<div className="">
				<RouteDecider GoTo={this.state.goTo}  HaveProfileLinkOutsideProfile={true} CheckProfieMode={this.context.ProfileMode} InsideProfile={false}></RouteDecider>
				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h3>Fax Sent Successfully!</h3>
					</div></div><br/>

				<div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						What would you like to do next?
					</div></div><br/>
					<div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 blue-link'>
						<button className='button-1'
								onClick={() => this.setRedirect('/coverletter')}>Send Another Fax
						</button>
					</div>
					</div><br/>

					<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 blue-link'>
						<button className='button-1'
								onClick={() => this.setRedirect('/inbox')}>Inbox
						</button>
					</div></div><br/>
					<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 blue-link'>
						<button className='button-1'
								onClick={() => this.setRedirect('/outbox')}>Outbox
						</button>
					</div></div><br/>
					<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 blue-link'>
						<button className='button-1'
								onClick={() => this.setRedirect('/userprofile')}>Profile
						</button>
					</div></div><br/>

			
			

	    	</div>
            
		     
		  )
}
};

export default FaxSent	;