import React from 'react'

import { UserContext } from '../../Providers'
import WorkingDiv from '../../WorkingDiv'
import RouteDecider from '../../RouteDecider'
import APIClient from '../../API'

class UpdateDetails extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props)

    this.SaveContact = this.SaveContact.bind(this);
    this.CompanyName = React.createRef();
    this.FirstName = React.createRef();
    this.LastName = React.createRef();
    this.Address1 = React.createRef();
    this.Address2 = React.createRef();
    this.City = React.createRef();
    this.State = React.createRef();
    this.PostalCode = React.createRef();
    this.Phone = React.createRef();
    this.Fax = React.createRef();
    this.Mobile = React.createRef();
    this.Country = React.createRef();


  }

  state = {
    goTo: '',
    working: false,
    ClientInfo: []
  }

  SaveContact() {

    if (this.FirstName.current.value === '') {
      alert('First name can not be null');
      return;
    }

    if (this.LastName.current.value === '') {
      alert('Last name can not be null');
      return;
    }


    this.setState({
      ...this.state,
      working: true
    });

    APIClient.saveContact(this.context.userObject.RecID, this.CompanyName.current.value, this.FirstName.current.value, this.LastName.current.value, this.Address1.current.value, this.Address2.current.value, this.City.current.value, this.State.current.value, this.Phone.current.value, this.Fax.current.value, this.Mobile.current.value, this.PostalCode.current.value, this.Country.current.value).then((data) => {
      if (data === 'Wow') {
        alert('Contact details updated.');
      }
      else {
        alert('Could not update details.');
      }

      this.setState({
        ...this.state,
        working: false,
      });
    }
    )
  }

  componentDidUpdate(prevProps) {

    if (this.context.ProfileMode === false) {
      this.setState({
        ...this.state,
        goTo: 'coverletter'
      });
    }

  }

  componentWillMount() {

    this.setState({
      ...this.state,
      working: true
    });

    APIClient.getUserDetails(this.context.userObject.RecID).then((data) => {
      if (data !== null && this.Country.current != null)
        this.Country.current.value = data.country;

      this.setState({
        ...this.state,
        working: false,
        ClientInfo: data
      });
    }

    )
  }


  render() {



    return (


      <div>
        <RouteDecider GoTo={this.state.goTo} CheckProfieMode={false} InsideProfile={true}></RouteDecider>
        <WorkingDiv working={this.state.working}></WorkingDiv>


        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <h3>Contact Details</h3>
          </div>
        </div>
        <br />

        <div className='row'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Company Name:</label>
            <input type="text" ref={this.CompanyName} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.companyName : ''} />
          </div>
        </div>


        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>First Name:</label>
            <input type="text" ref={this.FirstName} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.firstName : ''} />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Last Name:</label>
            <input type="text" ref={this.LastName} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.lastName : ''} />
          </div>
        </div>


        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Address 1:</label>
            <input type="text" ref={this.Address1} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.address1 : ''} />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Address 2:</label>
            <input type="text" ref={this.Address2} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.address2 : ''} />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>City:</label>
            <input type="text" ref={this.City} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.city : ''} />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Postal Code:</label>
            <input type="text" ref={this.PostalCode} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.postalCode : ''} />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>State:</label>
            <input type="text" ref={this.State} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.state : ''} />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Country:</label>
            <select defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.country : ''} ref={this.Country}>
              <option hidden value=''></option>
              <option value='United States'>United States</option>
              <option value='Canada'>Canada</option>
            </select>
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Phone:</label>
            <input type="text" ref={this.Phone} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.phone : ''} />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Fax:</label>
            <input type="text" ref={this.Fax} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.fax : ''} />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
            <label>Cellular Number:</label>
            <input type="text" ref={this.Mobile} defaultValue={(this.state.ClientInfo != null) ? this.state.ClientInfo.mobile : ''} />
          </div>
        </div>
        <br />
        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>

            <button className='button-1' onClick={this.SaveContact}>Update Contact Details</button>
          </div>
        </div>

        <br /><br /><br />
      </div>
    );
  }

};

export default UpdateDetails;