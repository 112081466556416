import React from 'react';
import './App.css';
import MyHeader from './header';
import MyMenu from './menu';
import { BrowserRouter as Router, Route } from "react-router-dom";
import SignUp from './SignUp';
import CoverLetter from './CoverLetter';
import AttachFile from './AttachFile';
import ChooseNumber from './ChooseNumber';
import ChoosePlan from './ChoosePlan';
import FreeFaxSent from './FreeFaxSent';
import FaxSent from './FaxSent';

import SendFax from './SendFax';
import { UserContext } from './Providers'

import Inbox from './Inbox';
import Outbox from './Outbox';
import Account from './Account';
import UserProfile from './UserProfile';
import UpdateDetails from './UserProfile/UpdateDetails';
import EmailPreference from './UserProfile/EmailPreference';
import PaymentInfoForPlan from './PaymentInfoForPlan';
import PaymentInfo from './PaymentInfo';

import {StripeProvider,Elements} from 'react-stripe-elements';

class AppRoutings extends React.Component {

  constructor(props) {



    super(props);
    this.state = {
      faxObject: { sCPSubject: '', sCPComments: '', sToFaxNumber: '', sCPToName: ''},
      sSRFaxNumber: '',
      files: [],
      planId: '-',
      userObject: { RecID: -1, name: '', token: '', email: '', imageUrl: '', sCPSubject: '', sCPComments: '', sToFaxNumber: '', sCPToName: '', accountStatus: '' },
      setUser: this.setUser,
      setFax: this.setFax,
      setPlanId: this.setPlanId,
      setProfileMode: this.setProfileMode,
      addFile: this.addFile,
      removeFile: this.removeFile,
      setsSRFaxNumber: this.setsSRFaxNumber,
      clearFiles: this.clearFiles,
      showHeaderMenu: false,
      ProfileMode: false,
      nextGoto: '',
    };

  }
  setUser = (recId, u_email, g_name, g_token, g_image, a_status) => {
    this.setState({
      ...this.state,
      userObject: { RecID: recId, name: g_name, token: g_token, email: u_email, imageUrl: g_image , accountStatus: a_status },
      showHeaderMenu: (recId === -1) ? false : true,
    });


    localStorage.setItem('userSession', JSON.stringify({ RecID: recId, name: g_name, token: g_token, email: u_email, imageUrl: g_image, accountStatus: ''  }));
  };



  setFax = (s_CPSubject, s_CPComments, s_ToFaxNumber, s_CPToName) => {
    this.setState({
      ...this.state,
      faxObject: { sCPSubject: s_CPSubject, sCPComments: s_CPComments, sToFaxNumber: s_ToFaxNumber, sCPToName: s_CPToName},
      
    });
    localStorage.setItem('faxSession', JSON.stringify({ sCPSubject: s_CPSubject, sCPComments: s_CPComments, sToFaxNumber: s_ToFaxNumber, sCPToName: s_CPToName }));
  };

  setsSRFaxNumber = (sSRFaxNumber) => {
    this.setState({
      ...this.state,
      sSRFaxNumber: sSRFaxNumber
    });

    //localStorage.setItem('faxNumberSession', JSON.stringify({ sSRFaxNumber: sSRFaxNumber}));
  };

  setPlanId = (plan) => {
    this.setState({
      ...this.state,
      planId: plan
    });

  };


  setProfileMode = (mode, goto) => {
    this.setState({
      ...this.state,
      ProfileMode: mode,
      nextGoto: goto
    });

  };

  removeFile = (index) => {
    var clone = this.state.files.slice(0);
		clone.splice(index, 1);
		this.setState({...this.state,
			files: clone, 
		});
  };

  addFile = (name,) => {
    this.setState({
      ...this.state,
      files: this.state.files.concat({filename: name}), 
    });
  };

    clearFiles = () => {
      this.setState({
        ...this.state,
        files: []
      });
    };

  componentWillMount() {
    var session = localStorage.getItem('userSession');

    //console.log(session)
    if (session !== '' && session !== undefined && session !== null) {
      this.setState(state => ({
        userObject: JSON.parse(session),
        showHeaderMenu: ( JSON.parse(session).RecID === -1)? false:true
      }));

    }

    var session2 = localStorage.getItem('faxSession');

    if (session2 !== '' && session2 !== undefined  && session2 !== null) {
      this.setState(state => ({
        faxObject: JSON.parse(session2),
      }));
    }

  }



  render() {


    return (
      <Router>
        <UserContext.Provider value={this.state}>
        <StripeProvider apiKey="pk_live_mliQt1WtGfm2C97kb0MrKuLc">
        <Elements>
          <div className="container">

            <MyHeader showMenu={this.state.showHeaderMenu} />

            <div className="row">
              <div className='col-lg-3'>
                <MyMenu></MyMenu>
              </div>
              <div className='col-lg-9 page-padder'>

                <Route  path="/" component={SignUp}  exact={true} />
                <Route exact path="/coverletter" component={CoverLetter} />
                <Route exact path="/attachfile" component={AttachFile} />
                <Route exact path="/choosenumber" component={ChooseNumber} />
                <Route exact path="/chooseplan" component={ChoosePlan} />
                <Route exact path="/sendfax" component={SendFax} />
                <Route exact path="/inbox" component={Inbox} />
                <Route exact path="/outbox" component={Outbox} />
                <Route exact path="/account" component={Account} />
                
                <Route exact path="/freefaxsent" component={FreeFaxSent} />
                <Route exact path="/faxsent" component={FaxSent} />

                <Route exact path="/userprofile" component={UserProfile} />
                <Route exact path="/userprofile/updatedetails" component={UpdateDetails} />
                <Route exact path="/userprofile/editemail" component={EmailPreference} />
                <Route exact path="/userprofile/payment" component={PaymentInfo} />
                
                  <Route exact path="/payment" component={PaymentInfoForPlan} />
                
                
              </div>
            </div>
          </div>
          </Elements>
          </StripeProvider>
        </UserContext.Provider>
      </Router>
    )
  }
}




export default AppRoutings
