import React from 'react'
import HeaderProfile from '../HeaderProfile';
import { UserContext} from '../Providers'


class MyHeader extends React.Component{

	static contextType = UserContext;
  
      constructor(props) {
          super(props)
  
		   this.ChangeProfileMode = this.ChangeProfileMode.bind(this);

	  }
	  
	  
	  ChangeProfileMode(){
		this.context.setProfileMode(false, '/coverletter');
	  }

	  render() {
		  return (
		  	 
		  	<div className='row headerRow'>
                  <div className='col-lg-2'><img onClick={this.ChangeProfileMode} className='img-fluid hand' src='/images/afax-logo.png'  alt='' /></div>
                  <div className='col-lg-10'><HeaderProfile showMenu={this.props.showMenu}></HeaderProfile></div>
		  	    
	    	</div>
            
		     
		  )
}
};

export default MyHeader;