import React from 'react'
import {UserContext} from '../Providers'
import WorkingDiv from '../WorkingDiv'
import RouteDecider from '../RouteDecider'
import {Redirect} from "react-router-dom";
import APIClient from '../API'

class UserProfile extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props)

        this.setRedirect = this.setRedirect.bind(this);
        this.GetFaxNumber = this.GetFaxNumber.bind(this);
        this.CancelAccount = this.CancelAccount.bind(this);
    }

    state = {
        goTo: '',
        working: false,
        faxNumbermessage: '',
        showupdatecard: false
    }

    CancelAccount() {
        var r = window.confirm("Are you sure you want to cancel your subscription? You can not use this service after this action.");
        if (r === true) {
            this.setState({	...this.state,	working: true	});
            APIClient.cancelAccount(this.context.userObject.RecID).then((data)=> {
                if(data !== "Wow!"){
                    alert('Some Error Happened. Can not Cancel This Account at the Moment.');
                    this.setState({	...this.state,	working: false	});
                }
                else{
                    this.context.setUser(-1, '','', '', '', '');
                    this.context.userObject = { RecID: -1, name: '', token: '', email: '', imageUrl: '', sCPSubject: '', sCPComments: '', sToFaxNumber: '', sCPToName: '', accountStatus: '' };
                    this.context.showHeaderMenu = false;

                    this.context.setFax('','','','');
                    this.context.faxObject = { sCPSubject: '', sCPComments: '', sToFaxNumber: '', sCPToName: ''};
                    this.context.clearFiles();

                    this.context.setsSRFaxNumber('');
                    this.setState({...this.state, goTo: '/signup'});
                }
            })
        }

    }

    setRedirect(path) {
        this.setState({goTo: path})
    }

    componentDidUpdate(prevProps) {
        if (this.context.ProfileMode === false) {
            this.setState({...this.state, goTo: '/coverletter'});
        }
    }

    componentWillMount() {
        this.setState({working: true});
        APIClient.getFaxNumber(this.context.userObject.RecID).then((data) => {
            this.setState({working: false, faxNumbermessage: data});
        })

        APIClient.getUserStatus(this.context.userObject.RecID).then((data) => {
            if(data !== 'Signup' && data !== 'Canceled')
                this.setState({working: false, showupdatecard: true});
        })
    }

    GetFaxNumber() {

    }


    render() {

        if (this.state.goTo !== '') {
            return <Redirect to={this.state.goTo}/>
        }
        return (


            <div>
                <RouteDecider GoTo={this.state.goTo} CheckProfieMode={false} InsideProfile={true}></RouteDecider>
                <WorkingDiv working={this.state.working}></WorkingDiv>


                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <h3>Profile</h3>
                    </div>
                </div>


                <div className='row margin-top-15'>
                    <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>

                        <div className='row'>
                            <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
                                <button className='button-1'
                                        onClick={() => this.setRedirect('/userprofile/updatedetails')}>View Contact
                                    Details
                                </button>
                            </div>

                        </div>

                        <div className={this.state.showupdatecard? "row margin-top-15" : "hideme"}>
                            <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
                                <button className='button-1'
                                        onClick={() => this.setRedirect('/userprofile/payment')}>Update Credit Card
                                </button>
                            </div>

                        </div>

                        <div className='row margin-top-15'>
                            <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
                                <button className='button-1'
                                        onClick={() => this.setRedirect('/userprofile/editemail')}>Edit Email
                                    Preferences
                                </button>
                            </div>
                        </div>

                        <div className={this.state.showupdatecard? "row margin-top-15" : "hideme"}>
                            <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
                                <button className='button-1' onClick={() => this.CancelAccount()}>Cancel My Account
                                </button>
                            </div>
                        </div>
                        <br/>

                        <div className='row '>
                            <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12 margin-left-3'>
                                Your Fax number is: <b>{this.state.faxNumbermessage}</b>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        );
    }

};

export default UserProfile;