import React from 'react'
import { UserContext} from '../Providers'
import {Redirect } from "react-router-dom";

import APIClient from '../API'


class LoginButton extends React.Component{

  static contextType = UserContext;

    constructor(props) {
        super(props)

		    this.onSignIn = this.onSignIn.bind(this);
    }

    state = {
      goTo: '',
    }
    
    onSignIn(googleUser) {

        var profile = googleUser.getBasicProfile();

        var mythis = this;
        APIClient.loginUser(profile.getEmail(), googleUser.getAuthResponse().id_token, profile.getName(), profile.getImageUrl() ).then((data) =>
        {
          if(data.userID !== -1){
            mythis.context.setUser(data.userID, profile.getEmail(),profile.getName(), googleUser.getAuthResponse().id_token, profile.getImageUrl(), data.AccountStatus);
          }   
          else{
            alert("An error happened in signing in.");
          }
        }
        
       )
    }
  

    componentDidMount() {
    
      if(this.context.userObject.RecID === -1){
        window.gapi.signin2.render('g-signin2', {
          'scope': 'https://www.googleapis.com/auth/plus.login',
          'width': 250,
          'height': 50,
          'longtitle': true,
          'theme': 'dark',
          'onsuccess': this.onSignIn
      })
      }
       
    }
  
    render() {
      
      if (this.state.goTo === 'coverletter') {
	      return <Redirect to='/coverletter' />
      }
      
      
      
      return (
        <div id="g-signin2"></div>
      );
    }
  
  };

  export default LoginButton;