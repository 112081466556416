import React from 'react'
import { UserContext} from '../Providers'
import FilesGrid  from '../FilesGrid'
import APIClient from '../API'
import Utility from '../API/util'
import RouteDecider  from '../RouteDecider'
import WorkingDiv  from '../WorkingDiv'
import ReactTooltip from 'react-tooltip'

class SendFax extends React.Component{

	static contextType = UserContext;

	constructor(props) {
		super(props)
     
        this.sCPSubject = React.createRef();
        this.sCPComments = React.createRef();
        this.sToFaxNumber = React.createRef();
		this.sCPToName = React.createRef();

		this.SendFax = this.SendFax.bind(this);
	}


	state = {
		goTo: '',
		working: false
	  }

	componentWillMount() {
		if(this.context.userObject.RecID === -1){
		  this.setState({...this.state,
			goTo: '/'});
		}
		else if (this.context.files.length === 0) {
			this.setState({	...this.state,	goTo: '/attachfile'	});
		}
		else{
			this.setState({	...this.state,	working: true	});
			APIClient.canSendFax(this.context.userObject.RecID).then((data)=> {
				if(data === "past_due" || data === "unpaid" ){
					alert("You have an unpaid invoice, please update your card info so it can be charged.");

					this.context.setProfileMode(true, '/userprofile/payment');
				}
				else if(data === "canceled" || data === "no-trial"){
					this.setState({	...this.state,	goTo: '/chooseplan'	});
				}
				else if(data === "active" || data === "trial" || data === "trialing"){
					this.setState({	...this.state,	working: false	});
				}
			})
		}
	}

	SendFax(){

		if(this.sToFaxNumber.current.value === ''){
			alert("Please Enter the Number You're Sending the Fax to.");
			return;
		}

		var subject = "-";
		if(this.sCPSubject.current.value !== ''){
			subject = this.sCPSubject.current.value;
		}

		var comment = "-";
		if(this.sCPComments.current.value !== ''){
			comment = this.sCPComments.current.value
		}

		
		var to = "-";
		if(this.sCPToName.current.value !== ''){
			to = this.sCPToName.current.value;
		}

		var toNumber = this.sToFaxNumber.current.value;

		toNumber = toNumber.trim();

		if(!Utility.validNumber(toNumber)){
			alert("Send Fax to number should be 10 digits.");
			return;
		}

		this.setState({...this.state,
			working: true});
		var filenames = "";

			this.context.files.forEach(item => filenames += "," + item.filename);
			
			this.context.setFax(subject, comment, toNumber, to);

			APIClient.sendFax(this.context.userObject.RecID, filenames, subject,
				comment,
				toNumber,
				to)
		.then((data1) =>
		{
			//console.log(data1);
			if(data1 === 'Free Fax Sent.'){
				this.context.setProfileMode(false, '/freefaxsent');
				this.setState({...this.state,working:false,goTo: '/freefaxsent'});
			}
			else if(data1 === 'Fax Sent'){
				this.context.setProfileMode(false, '/faxsent');
				this.setState({...this.state,working:false, goTo: '/faxsent'});
			}
			else {
				alert(data1);
				this.setState({...this.state, working:false});
		}

			
		});
	}


	  render() {


		  return (
		  	 
		  	<div>
				<RouteDecider CheckFaxNumber={true} CheckFiles={true}  GoTo={this.state.goTo} CheckProfieMode={this.context.ProfileMode}></RouteDecider>
				<WorkingDiv working={this.state.working}></WorkingDiv>
				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h3>Send Your Fax.</h3>
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-5 col-md-6 col-sm-6 col-xs-6 '>
						<label><span>Send To: <img
							data-tip="10 Digit fax number. We do not support international numbers. (Example of supported number: 9787936893)"
							src='/images/help.svg' alt='' className='helpIcon' width='18px'/></span></label>
						<input ref={this.sToFaxNumber} defaultValue={(this.context.faxObject !== null)? this.context.faxObject.sToFaxNumber : ""}  type="text" name="sendtonumber" placeholder="Enter Fax Number Here..."></input>
					</div>
					<div className='col-lg-5 col-md-6 col-sm-6 col-xs-6 '>
					<label><span>Deliver To / Attention:</span></label>
                        <input ref={this.sCPToName} defaultValue={(this.context.faxObject !== null)? this.context.faxObject.sCPToName : ""} type="text" name="subjectline" placeholder="Enter Recipient Name Here..."></input>
					</div>
				</div>

				<div className='row marginer-top-bottom'>
					<div className='col-lg-10 col-md-10 col-sm-12 col-xs-12 '>
						<label><span>From:</span></label>
						<span> {this.context.userObject.name}</span>
					</div>
					
				</div>

				<div className='row'>
					<div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
						<label><span>Cover Letter Subject Line:</span></label>
                        <input ref={this.sCPSubject} defaultValue={(this.context.faxObject !== null)? this.context.faxObject.sCPSubject : ""} type="text" name="deliverto" placeholder="Enter the Subject Line..."></input>

					</div>
				</div>
				<br/>
				<div className='row'>
					<div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
						<label><span>Cover Letter Comments:</span></label>
						<textarea ref={this.sCPComments} defaultValue={(this.context.faxObject !== null)? this.context.faxObject.sCPComments : ""}  rows="5" name="subjectline" placeholder="Enter the Cover Letter Comments..."></textarea>
					</div>
				</div>

				<br/>
				<div className='row'>
					<div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
						<label><span>Attachments:</span></label>
					</div>
				</div>
				<FilesGrid showRemove='false'></FilesGrid>

				<br/>	<br/>
				<div className='row'>
					<div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
						<button name="sendFax" onClick={this.SendFax} className="nextBtn">Send Fax Now</button>
					</div>
				</div>
<br/>
				<ReactTooltip />
	    	</div>
            
		     
		  )
}
};

export default SendFax;