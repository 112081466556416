import axios from 'axios';

const BASE_URI = 'https://api.afax.com/api';
//https://localhost:44313/  https://devapp.afax.com/api
//const BASE_URI = 'https://localhost:44339/api';
//https://localhost:44372/https://localhost:44372/
const client = axios.create({
    baseURL: BASE_URI,
    json: true
});

const APIClient = {


    fixedEncodeURIComponent: function (str) {
        return encodeURIComponent(str).replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
    },
    getTollFreeAreaCodes: function () {
        return this.perform('get', '/numbers/GetTollFreeAreaCodes', null, 'application/json');
    },

    getPaidAreaCodes: function () {
        return this.perform('get', '/numbers/GetPaidAreaCodes', null, 'application/json');
    },

    GetCountryByNumber: function (number) {
        return this.perform('get', '/numbers/GetCountryByNumber/' + number, null, 'application/json');
    },

    getGetPaidNumbers: function (areacode, areatext) {
        return this.perform('get', '/numbers/GetPaidNumbers/' + areacode + '/' + areatext, null, 'application/json');
    },

    loginUser: function (email, token, name, profileImage) {
        return this.perform('post', '/Login/Post?email=' + email + '&token=' + token + '&name=' + name + '&profileImage=' + profileImage, null, 'application/x-www-form-urlencoded; charset=UTF-8');
    },

    getAccountData: function (userid) {
        return this.perform('get', '/Login/GetAccountData/' + userid, null, 'application/json');
    },

    getOutbox: function (userid) {
        return this.perform('get', '/Login/GetOutBox/' + userid, null, 'application/json');
    },

    getUserDetails: function (userid) {
        return this.perform('get', '/Login/GetUserDetails/' + userid, null, 'application/json');
    },

    getInbox: function (userid) {
        return this.perform('get', '/Login/GetInbox/' + userid, null, 'application/json');
    },

    checkPageCounts: function (userid, files) {
        return this.perform('get', '/File/CheckPageCounts/' + userid + '/' + files, null, 'application/json');
    },

    deleteOutbox: function (userid, filename) {
        return this.perform('get', '/Login/DeleteOutbox/' + userid + '/' + filename, null, 'application/json');
    },

    deleteInbox: function (userid, filename) {
        return this.perform('get', '/Login/DeleteInbox/' + userid + '/' + filename, null, 'application/json');
    },

    getEmailProfile: function (userid) {
        return this.perform('get', '/Login/GetEmailProfile/' + userid, null, 'application/json');
    },

    getUserStatus: function (userid) {
        return this.perform('get', '/Login/GetUserStatus/' + userid, null, 'application/json');
    },

    canSendFax: function (userid) {
        return this.perform('get', '/Fax/CanSendFax/' + userid, null, 'application/json');
    },

    getFaxNumber: function (userid) {
        return this.perform('get', '/Fax/GetFaxNumber/' + userid, null, 'application/json');
    },


    cancelAccount: function (userid) {
        const data = new FormData();
        data.append('userid', userid);

//var data1 = 'data=' + data + '&userid=' + userid + '&filename=' + filename;
        return this.perform('post', '/Login/CancelAccount', data, 'multipart/form-data');

    },

    postFile: function (userid, file) {

        const data1 = new FormData();
        data1.append('file', file);
        data1.append('userid', userid);

//var data1 = 'data=' + data + '&userid=' + userid + '&filename=' + filename;
        return this.perform('post', '/File/Post', data1, 'multipart/form-data');

    },

    saveContact: function (userid, companyname, firstname, lastname, address1, address2, city, state, phone, fax, cell, postalcode, country) {

        const data = new FormData();
        data.append('userid', userid);
        data.append('companyname', companyname);
        data.append('firstname', firstname);
        data.append('lastname', lastname);
        data.append('address1', address1);
        data.append('address2', address2);
        data.append('state', state);
        data.append('city', city);
        data.append('phone', phone);
        data.append('fax', fax);
        data.append('cell', cell);
        data.append('postalcode', postalcode);
        data.append('country', country);

        return this.perform('post', '/Login/SaveContact', data, 'multipart/form-data');

    },

    saveEmailProfile: function (userid, primaryemail, email1, email2, timezone) {

        const data = new FormData();
        data.append('userid', userid);
        data.append('primaryemail', primaryemail);
        data.append('email1', email1);
        data.append('email2', email2);
        data.append('timezone', timezone);

        return this.perform('post', '/Login/SaveEmailProfile', data, 'multipart/form-data');

    },

    createStripeCustomer: function (userid, sourceid, planid) {

        const data = new FormData();
        data.append('userid', userid);
        data.append('sourceid', sourceid);
        data.append('planid', planid);

        return this.perform('post', '/Stripe/CreateStripeCustomer', data, 'multipart/form-data');

    },

    updateCC: function (userid, sourceid) {

        const data = new FormData();
        data.append('userid', userid);
        data.append('sourceid', sourceid);

        return this.perform('post', '/Stripe/UpdateCC', data, 'multipart/form-data');

    },

    createFaxUser: function (userid, faxnumber) {

        const data = new FormData();
        data.append('userid', userid);
        data.append('faxnumber', faxnumber);

        return this.perform('post', '/Fax/CreateFaxUser', data, 'multipart/form-data');

    },

    sendFax: function (userid, filenames, sCPSubject, sCPComments, sToFaxNumber, sCPToName) {

        const data = new FormData();
        data.append('userid', userid);
        data.append('filenames', filenames);
        data.append('sCPSubject', sCPSubject);
        data.append('sCPComments', sCPComments);
        data.append('sToFaxNumber', sToFaxNumber);
        data.append('sCPToName', sCPToName);


//var data1 = 'data=' + data + '&userid=' + userid + '&filename=' + filename;
        return this.perform('post', '/Fax/Post', data, 'multipart/form-data');

    },

    async perform(method, resource, data, headerType) {
        return client({
            method,
            url: resource,
            data: data,
            config: {headers: {'Content-Type': headerType}}

        }).then(resp => {
            return resp.data ? resp.data : [];
        })
            .catch(error => {
                console.log(error);
            });
    }
}

export default APIClient;