import React from 'react'
import { UserContext } from '../Providers'
import WorkingDiv from '../WorkingDiv'
import RouteDecider from '../RouteDecider'

import APIClient from '../API'

class OutBox extends React.Component {

  static contextType = UserContext;

  constructor(props) {
		super(props)
		this.Refresh = this.Refresh.bind(this);   
	}

  state = {
    goTo: '',
    data: [],
    working: false,
  }

  componentDidUpdate(prevProps) {
    if (this.context.ProfileMode === false) {
      this.setState({...this.state, goTo: 'coverletter'});
    }
  }

  componentWillMount() {
    if (this.context.ProfileMode === false) {
      this.setState({  ...this.state,  goTo: '/coverletter' });
    }

    else {
      this.Refresh();
    }
  }

  Refresh(){
    this.setState({
      ...this.state,
      working: true
    });

    APIClient.getOutbox(this.context.userObject.RecID)
      .then((data1) => {
        this.setState({
          ...this.state,
          data: data1,
          working: false
        });

      });
  }

  RemoveMe(filename){
    var r = window.confirm("Are you sure you want to delete this fax?");
    if (r === true) {
      this.setState({
        ...this.state,
        working: true
      });

      APIClient.deleteOutbox(this.context.userObject.RecID, filename)
        .then((data2) => {
          this.Refresh();
        });
    } 
  }


  render() {

    

    return (


      <div>
        <RouteDecider GoTo={this.state.goTo} CheckProfieMode={false}></RouteDecider>
        <WorkingDiv working={this.state.working}></WorkingDiv>


        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <h3>Outbox</h3>
          </div>
        </div>


        <div className='row'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>

          <div className='row gridrow gridrowheader'>
            
            <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'></div>
              <div className='col-lg-4 col-md-2 col-sm-2 col-xs-2'>SENT TO</div>
              <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>DATE SENT</div>
              <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>STATUS</div>
              <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>PAGES</div>
              <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'></div>
            </div>

            {(this.state.data === []  || this.state.data === undefined ) ? '' : this.state.data.map((r, key) => 
            
            
            <div key={key} className='row gridrow'>
            
            <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'>
            <div className='removeBtn'   onClick={() => this.RemoveMe(r.fileName)}><img src='images/trash.svg' alt='' width='20'></img></div>	
            </div>
              <div className='col-lg-4 col-md-2 col-sm-2 col-xs-2'>{r.toFaxNumber}</div>
              <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>{r.dateSentString}</div>
              <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>{r.sentStatus}</div>
              <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>{r.pages}</div>
              <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'>
                <a target='_blank' rel="noopener noreferrer" href={'https://api.afax.com/api/file/GetFile/'+this.context.userObject.RecID+'/'+r.fileName+ '/out'}><i className="fa fa-file-pdf-o" aria-hidden="true"></i></a>
              </div>
            </div>
            
            
            
            )}
          </div>
        </div>


      </div>
    );
  }

};

export default OutBox;