import React from 'react'
import { UserContext} from '../Providers'
import WorkingDiv  from '../WorkingDiv'
import FilesGrid  from '../FilesGrid'

import RouteDecider  from '../RouteDecider'

import APIClient from '../API'

class AttachFile extends React.Component{

	static contextType = UserContext;

	constructor(props) {
		super(props)
		
     
		this.Next = this.Next.bind(this);   
		this.SelectFile = this.SelectFile.bind(this);
	}

	state = {
		goTo: '',
		myfiles: [],
		working: false
	  }

	componentWillMount() {
		if(this.context.userObject.RecID === -1){
		  this.setState({...this.state,
			goTo: '/'});
		}
		else if(this.context.faxObject.sToFaxNumber === ''){
			this.setState({...this.state,
				goTo: '/coverletter'});
		}
		else{
			this.setState({...this.state,
				myfiles: this.context.files});
		}

	
	}

	Next(){
		if(this.state.myfiles.length === 0){
			alert('Please choose a file');
		}
		else{

			this.setState({...this.state,working: true});

		APIClient.getUserStatus(this.context.userObject.RecID).then((data) =>{
			if(data === 'Active' || data === 'Past_Due' || data === 'Trialing'){
				this.setState({...this.state,working: false,goTo: '/sendfax'});		
			}
			else{
				this.setState({...this.state,working: false,goTo: '/choosenumber'});
			}
		});
		}
	}

	SelectFile(event){
		
		if(event.target.files[0] === undefined)
			return;

			this.setState({...this.state,
				working: true, 
				});
		

		APIClient.postFile(this.context.userObject.RecID, event.target.files[0])
		.then((data1) =>
		{
			if(data1 !== 'Error'){
				this.context.addFile(data1);

			  this.setState({...this.state,myfiles: this.state.myfiles.concat({filename: data1}), working: false});
			}
			else{
				alert('Could not convert selected file to PDF format. Please select another file.');
				this.setState({...this.state,working: false});
			}
		});
	}

	RemoveMe(index){
		
		var clone = this.state.myfiles.slice(0);
		clone.splice(index, 1);
		this.setState({...this.state,myfiles: clone, });

		this.context.removeFile(index);

	}
	  render() {
	
	

		  return (
		  	 
		  	<div>

				<RouteDecider GoTo={this.state.goTo} CheckProfieMode={this.context.ProfileMode}></RouteDecider>
				<WorkingDiv working={this.state.working}></WorkingDiv>
				
				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h3>Attach your files</h3>
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 subheader'>
						<h4>Ok, you’re cover sheet’s all set!</h4>
					</div>
				</div>

				<div className='row'>
				<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h4>Now let’s attach a document that will be sent as your actual fax</h4>
						<div className="smallFootage">Free account limited to 1 fax up-to 2 page.</div>
					</div>
				</div>


				<br/>
			<div className='row'>
				<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
					<div className="fileUpload btn btnUpload">
					<span><img width='20' className='question-mark' src='images/question-mark.svg' alt='' />Choose File</span>
					<input accept='.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf' type="file" onChange={this.SelectFile} className="upload"/>
					</div>
					
					</div>
				</div>

				<br/>
				
				<FilesGrid showRemove='true'></FilesGrid>



				<br/><br/>
				<div className='row'>
					<div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
						
						<button name="nextstep2" onClick={this.Next} className="nextBtn">Next Step</button>
					</div>
				</div>
<br/>
			
<br/>
	    	</div>
            
		     
		  )
}
};

export default AttachFile	;