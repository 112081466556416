import React from 'react'
import { UserContext} from '../Providers'
//import {Redirect } from "react-router-dom";
import RouteDecider  from '../RouteDecider'

class ChoosePlan extends React.Component {

	constructor(props) {
        super(props)
		
		this.state = {
            plan1Shadow: false,
        }   

		this.selectRadio = this.selectRadio.bind(this);
		this.Buy = this.Buy.bind(this);
		
		// ref to controls
        this.Plan1 = React.createRef();
		this.Plan2 = React.createRef();
		
		this.Plan1Container = React.createRef();
		this.Plan2Container = React.createRef();
	}

	static contextType = UserContext;

	state = {
		goTo: '',
	  }

	  Buy(plan) {
		  this.context.setPlanId(plan);
		  this.setState({...this.state, goTo: '/payment'});
	  }

	componentWillMount() {
		//console.log(this.context.sSRFaxNumber);
		if(this.context.userObject.RecID === -1){ this.setState({...this.state,goTo: '/'});}

	}

	selectRadio(event) {

		this.setState({
            ...this.state,
            plan1Shadow: (this.Plan1.current.checked === true) ? true : false,
        });

		if(this.Plan1.current.checked === true){
			this.Plan1Container.current.className = "radioDiv selectRadioDiv";
		}
		else{

			this.Plan1Container.current.className = "radioDiv";
		}

		if(this.Plan2.current.checked === true){
			this.Plan2Container.current.className = "radioDiv selectRadioDiv";
		}
		else{
			this.Plan2Container.current.className = "radioDiv";
		}
    }
	
	render() {

		return (
			
			<div>
				<RouteDecider GoTo={this.state.goTo} CheckProfieMode={this.context.ProfileMode} InsideProfile={false}></RouteDecider>
				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h3>Fax Numbers</h3>
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 subheader'>
						<h4>Ok, let’s get your fax number!</h4>
						<div>With your free account you can send up to 2 pages per month.
						</div>
						<div>
							If you would like to receive a fax and be able to send directly from your Gmail account, let’s get your own fax number. Deal?
						</div>
						<div>
							One-time only trial period.
						</div>
					</div>
				</div>


				<br />

				<div className='row hideme'>
					<div className='col-lg-5 col-md-6 col-sm-6 col-xs-6 '>
						<div className="radioDiv" ref={this.Plan1Container}>
							<input type="radio" ref={this.Plan1} onChange={this.selectRadio} name="planoption"></input>
							<span>Billed Monthly</span>
						</div>

					</div>


					<div className='col-lg-5 col-md-6 col-sm-6 col-xs-6 '>
						<div className="radioDiv selectRadioDiv" ref={this.Plan2Container}>
							<input type="radio" ref={this.Plan2} onChange={this.selectRadio} checked={!this.state.plan1Shadow} name="planoption"></input>
							<span>Billed Annualy</span>
							<div className="bestdeal">Best Deal</div>
						</div>

					</div>

				</div>

				<br />
				<div className="row">
					<div className='col-lg-3 col-md-3 col-sm-3 col-xs-3 '>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-bottom text-left">
								PLANS
						</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-left'>
								Price
					</div>

							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-left'>
								Pages Included
					</div>

							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-left'>
								Secure Storage
					</div>

							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-left'>
								Fax from Email
					</div>

							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-left'>
								Recipients
					</div>



					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center small-text'>
						<br/>
					Free account limited to 1 fax of up-to 2 pages
					</div>


						</div>
					</div>


					<div className='col-lg-2 col-md-2 col-sm-3 col-xs-3 '>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-bottom text-left">
								FREE
						</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-left'>
								$0
						</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-left'>
								2
						</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-left'>
								<img src='images/checkmark.svg' className='icon-img' alt='' />
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-left' style={{ color: 'white' }}>
								-
						</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-left' style={{ color: '#f2f6fb' }}>
								-
					</div>

						</div>
					</div>


					<div className={"col-lg-3 col-md-3 col-sm-3 col-xs-3 " + ((this.state.plan1Shadow === true)? "shadow-side" : "")}>
						<div className="row">
							<div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center " + ((this.state.plan1Shadow === true)? "a-cell" : "border-bottom")}>
								SOLOPRENUER
						</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center no-padding-left'>
								$9.95/mo
					</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center'>
								200
					</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center'>
								<img src='images/checkmark.svg' className='icon-img' alt='' />
							</div>

							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center'>
								<img src='images/checkmark.svg' className='icon-img' alt='' />
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center'>
								<img src='images/checkmark.svg' className='icon-img' alt='' />
							</div>


							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center'>
								<br/>
							<button name="buynow2"  onClick={() => this.Buy('MonthlySoloprenuer') } className={(this.state.plan1Shadow === true)? "buyNow1" : "buyNow2"}>Buy Now</button>
							</div>

						</div>

					</div>

					<div className={"col-lg-3 col-md-3 col-sm-3 col-xs-3 " + ((this.state.plan1Shadow === false)? "shadow-side" : "")}>

						<div className="row">
							<div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center " + ((this.state.plan1Shadow === false)? "a-cell" : "border-bottom")} >
								SMALLBIZ
						</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center no-padding-left'>
								$14.95/mo
					</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center'>
								500
					</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center'>
								<img src='images/checkmark.svg' className='icon-img' alt='' />
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center'>
								<img src='images/checkmark.svg' className='icon-img' alt='' />
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center'>
								<img src='images/checkmark.svg' className='icon-img' alt='' />
							</div>

							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center'>
								<br/>
							<button name="buynow1" onClick={() => this.Buy('MonthlySmallBiz') } className={(this.state.plan1Shadow === true)? "buyNow2" : "buyNow1"}>Buy Now</button>
							</div>
						</div>

					</div>

					<div className='col-lg-1 col-md-1 hidden-sm hidden-xs '>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-bottom text-center" style={{ color: 'white' }}>
								-
						</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center no-padding-left' style={{ color: '#f2f6fb' }}>
								-
					</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center' style={{ color: 'white' }}>
								-
					</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center no-padding-left' style={{ color: '#f2f6fb' }}>
								-
					</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center' style={{ color: 'white' }}>
								-
					</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell text-center no-padding-left margin-fixer1' style={{ color: '#f2f6fb'}}>
								-
					</div>

							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-cell2 text-center' style={{ color: 'white' }}>
								-
					</div>
						</div>
					</div>

				</div>

				
				<div className='row'>
				<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 '>
						
					
					</div>
					<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 '>
						
						<div>All paid plans come with a 7-day $1 trial.
						</div>

					</div>
				</div>

				<br />
			</div>


		)
	}
};

export default ChoosePlan;