import React from "react";

export const UserContext = React.createContext(
  { 
    faxObject: { sCPSubject: '', sCPComments: '', sToFaxNumber: '', sCPToName: ''},
    userObject: { RecID: -1, name: '', token: '', email: '', imageUrl: '', sCPSubject: '', sCPComments: '', sToFaxNumber: '', sCPToName: '' , accountStatus: ''},
    showHeaderMenu: false,
    ProfileMode: false,
      planId: '-',
    sSRFaxNumber: '',
    nextGoto: '',
    setProfileMode: () => {},
    setsSRFaxNumber: () => {},
    setUser: () => {},
    setFax: () => {},
      setPlanId: () => {}
}
);

// Create an exportable consumer that can be injected into components
export const UserConsumer = UserContext.Consumer

// Create the provider using a traditional React.Component class
class UserProvider extends React.Component {

	


  render () {
    return (
      // value prop is where we define what values 
      // that are accessible to consumer components
       <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default UserProvider