import React from 'react'

import { UserContext } from '../Providers'
import WorkingDiv from '../WorkingDiv'
import RouteDecider from '../RouteDecider'
import APIClient from '../API'
import {CardNumberElement, injectStripe, CardExpiryElement, CardCVCElement} from 'react-stripe-elements';

class PaymentInfoForPlan extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props)

    this.Submit = this.Submit.bind(this);

    this.createOptions = this.createOptions.bind(this);
    this.AgreeCheckBox = React.createRef();
  }

  state = {
    goTo: '',
    working: false,
    ClientInfo: []
  }

  Submit() {

    if(this.state.working === true){
      console.log('OOppsss');
      return;
    }

      if(this.AgreeCheckBox.current.checked === false){
          alert("Please confirm that you agree to terms and refund policy.");
          return;
      }

    this.setState({...this.state, working: true });

    this.props.stripe.createSource({
      type: 'card',
      owner: {
        name: this.context.userObject.name,
        email: this.context.userObject.email
      },
    }).then((payload) =>
    {
      if(payload.error !== undefined ){
        alert(payload.error.message);
        this.setState({...this.state, working: false });
        return;
      }
      else{
        if(payload.source !== undefined){
          APIClient.createStripeCustomer(this.context.userObject.RecID, payload.source.id, this.context.planId).then((data)=> {
            //console.log(data);
              if(data === 'Wow!'){
                APIClient.createFaxUser(this.context.userObject.RecID, this.context.sSRFaxNumber).then((data1) => {
                  if(data1 === 'Wow!'){
                    this.setState({...this.state, goTo: '/sendfax' });
                  }else{
                    alert(data1);
                    this.setState({...this.state, working: false });
                  }
                });
              }
              else{
                alert(data);
                this.setState({...this.state, working: false });
              }


          })
        }
        }
     
    });
  }
  

  componentDidUpdate(prevProps) {


  }

  componentWillMount() {
    if(this.context.planId === '-'){
      this.setState({...this.state, goTo: '/chooseplan'});
    }

    if(this.context.sSRFaxNumber === ''){
      alert('We redirect you so you choose a fax number first.');
      this.setState({...this.state,goTo: '/choosenumber'});
    }

  }

  createOptions() {
    return {
      style: {
        base: {
          color: '#424770',
          letterSpacing: '0.085em',
          fontFamily: 'ProximaNova-Regular, monospace',
          '::placeholder': {
            color: '#aab7c4',
          }
          

        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  }

  render() {
//console.log(this.context.ProfileMode);
    return (


      <div className="cardSection">
        <RouteDecider GoTo={this.state.goTo} CheckProfieMode={this.context.ProfileMode} InsideProfile={false}></RouteDecider>

        <WorkingDiv working={this.state.working}></WorkingDiv>


        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <h3>Payment Info</h3>
          </div>
        </div>

        <div className='row margin-top-15'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
          You will be charged $1 today (7 Day Trial)
          </div>
        </div>
    

        <div className='row  margin-top-15'>
          <div className='col-lg-10 col-md-11 col-sm-12 col-xs-12'>
          <label>Credit Card Number</label>
            <CardNumberElement {...this.createOptions()} className="form-control" />
          </div>
        </div>

        <div className='row  margin-top-15'>
          <div className='col-lg-5 col-md-11 col-sm-12 col-xs-12'>
          <label>Expiration Date</label>
            <CardExpiryElement {...this.createOptions()}  className="form-control" />
          </div>
       
          <div className='col-lg-5 col-md-11 col-sm-12 col-xs-12'>
          <label>CVC</label>
            <CardCVCElement {...this.createOptions()} className="form-control" />
          </div>
        </div>

        <div className='row margin-top-15'>

          <div className='col-lg-11 col-md-11 col-sm-11 col-xs-11'>
              <input type='checkbox' ref={this.AgreeCheckBox} /> I agree to the <a href="terms.html" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> as well as the <a rel="noopener noreferrer" href="refund.html" target="_blank">refund policy</a>.
          </div>
        </div>

        <br />
     

        <div className="form-group">
          
        
        <button className='button-1' onClick={this.Submit}>Submit Payment</button>
       </div>

        <br /><br /><br />
      </div>
    );
  }

};

export default injectStripe(PaymentInfoForPlan);