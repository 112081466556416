const Utility = {
	validateEmail: function (email) {
		return /.+@.+\.[A-Za-z]+$/.test(email);
	},

	validNumber: function (number) {
		if (number.length < 10)
			return false;

		if (number.length > 11)
			return false;

		return number.match(/^[0-9]+$/) != null;
	}
}

export default Utility;