import React from 'react'

import { Link } from "react-router-dom";
import RouteDecider from "../RouteDecider";
import {UserContext} from "../Providers";


class FreeFaxSent extends React.Component{
	static contextType = UserContext;
	state = {
		goTo: '',
		working: false
	  }

	
	  render() {
			//console.log(this.context.ProfileMode);
		  return (
		  	 
		  	<div className="">
				<RouteDecider GoTo={this.state.goTo} CheckProfieMode={this.context.ProfileMode} InsideProfile={false}></RouteDecider>
				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h3>Free Fax Sent!</h3>
					</div><br/><br/>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						See how easy that was?
					</div><br/>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 blue-link'>
                    Send another Fax? Click <Link className="" to="/coverletter">here</Link>
					</div><br/>
				</div>

			
			

	    	</div>
            
		     
		  )
}
};

export default FreeFaxSent	;