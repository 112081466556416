import React from 'react'
import { UserContext} from '../Providers'
import WorkingDiv  from '../WorkingDiv'
import RouteDecider  from '../RouteDecider'
//import APIClient from '../API'
import Utility from '../API/util'
import ReactTooltip from 'react-tooltip'

class CoverLetter extends React.Component{

	static contextType = UserContext;

	//static contextFaxType = FaxContext;

	constructor(props) {
		super(props)
		
        //ref to tooltips
        this.sCPSubject = React.createRef();
        this.sCPComments = React.createRef();
        this.sToFaxNumber = React.createRef();
		this.sCPToName = React.createRef();
		
		this.Next = this.Next.bind(this);
	}

	

	Next(){

		
		if(this.sToFaxNumber.current.value === ''){
			alert("Please Enter the Number You're Sending the Fax to.");
			return;
		}

		var subject = "-";
		if(this.sCPSubject.current.value !== ''){
			subject = this.sCPSubject.current.value;
		}

		var comment = "-";
		if(this.sCPComments.current.value !== ''){
			comment = this.sCPComments.current.value
		}

		
		var to = "-";
		if(this.sCPToName.current.value !== ''){
			to = this.sCPToName.current.value;
		}

		var toNumber = this.sToFaxNumber.current.value;
		toNumber = toNumber.trim();

		if(!Utility.validNumber(toNumber)){
			alert("Send Fax to number should be 10 digits.");
			return;
		}

		this.context.setFax(subject, comment, toNumber, to);
			
		this.setState({...this.state,
			working:false,
			goTo: '/attachfile'});
		
	}
	
	state = {
		goTo: '',
		working: false
	  }

	componentWillMount() {
		

		if(this.context.userObject.RecID === -1){
		  this.setState({...this.state,
			goTo: '/'});
		}
	}

	componentDidMount() {
	
	}

	  render() {

		 

		  return (
		  	 
		  	<div>
				  <RouteDecider GoTo={this.state.goTo} CheckProfieMode={this.context.ProfileMode}></RouteDecider>
				   <WorkingDiv working={this.state.working}></WorkingDiv>
				

				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<h3>Great! Your account’s setup.</h3>
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 subheader'>
						<h4>Let’s send a fax. Please, enter the fax number you’d like to send a fax.</h4><br/>
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-5 col-md-6 col-sm-6 col-xs-6 '>
						<label><span>Send To: <img alt='' data-tip="10 Digit fax number. We do not support international numbers. (Example of supported number: 9787936893)" src='/images/help.svg' className='helpIcon' width='18px'/></span></label>
						<input ref={this.sToFaxNumber} defaultValue={(this.context.faxObject !== null)? this.context.faxObject.sToFaxNumber : ""}  type="text"  placeholder="Enter Fax Number Here..."></input>
					</div>
					<div className='col-lg-5 col-md-6 col-sm-6 col-xs-6 '>
					<label><span>Deliver To / Attention:</span></label>
						<input ref={this.sCPToName} defaultValue={(this.context.faxObject !== null)? this.context.faxObject.sCPToName : ""} type="text"  placeholder="Enter Recipient Name Here..."></input>

					</div>
				</div>
<br/>
				<div className='row'>
					<div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
						<label><span>Cover Letter Subject Line:</span></label>
						<input ref={this.sCPSubject} defaultValue={(this.context.faxObject !== null)? this.context.faxObject.sCPSubject : ""}  type="text"  placeholder="Enter the Subject Line..."></input>
					</div>
				</div>
				<br/>
				<div className='row'>
					<div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
						<label><span>Cover Letter Comments:</span></label>
						<textarea ref={this.sCPComments} defaultValue={(this.context.faxObject !== null)? this.context.faxObject.sCPComments : ""} rows="5" placeholder="Enter the Cover Letter Comments..."></textarea>
						<div className="smallFootage">Free accounts limited to 1 fax of up-to 2 page</div>
					</div>
				</div>

				<br/>
				<div className='row'>
					<div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
						<button name="nextstep1" onClick={this.Next} className="nextBtn">Next Step</button>
					</div>
				</div>
<br/>
				<ReactTooltip />
	    	</div>
            
		     
		  )
}
};

export default CoverLetter;